<template>
    <div>
    	<div v-if="authUserPermission['my-task-view']">
	        <base-header class="pb-6">
	            <div class="row align-items-center py-4">
	                <div class="col-lg-6 col-7">
	                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
	                </div>
	            </div>
	        </base-header>
	        <div class="container-fluid mt--6 header-body">
	            <div class="row mt--5">
	                <div class="col-xl-3 col-md-6" v-for="(row, index) in widget.data">
	                    <router-link :to="{ name: 'My Task Show', params: { id: row.task_code }}">
	                        <stats-card :title="row.menu" :icon="row.icon" :sub-title="row.count.toString()" :type="row.attributes">
	                        </stats-card>
	                    </router-link>
	                </div>
	            </div>
	        </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
	import {mapState} from "vuex";
	import Api from '@/helpers/api';
	import myTaskDashboard from '@/services/dashboard/myTaskDashboard.service';
	import config from '@/configs/config';

	export default {
		data() {
			return {
				widget: {
					data: []
				},
				apiUrl: config.apiUrl,
			}
		},
		computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
		},
		mounted() {
			this.get();
		},
		methods: {
			get() {
				let context = this;
				Api(context, myTaskDashboard.get()).onSuccess(function(response) {
					context.widget.data = response.data.data;
				}).onError(function(error) {
					context.widget.data = [];
				}).call()
			}
		}
	};
</script>
